import * as React from 'react';

import { LoginFailureErrorPage } from '@hub-fe/app/ErrorPages';
import IsBrowser from '@hub-fe/common/IsBrowser';
import { DamlHubHelmet } from '@hub-fe/landing/nav';

const LoginFailurePage: React.FC = () => (
    <IsBrowser>
        <DamlHubHelmet />
        <LoginFailureErrorPage />
    </IsBrowser>
);

// Needed for Gatsby
// ts-unused-exports:disable-next-line
export default LoginFailurePage;
